// eslint-disable-next-line no-redeclare,no-unused-vars
function login() {
  $(document.body).addClass('login');
  var cont = $(
    '<div id="login" class="p-4 p-md-5 bg-white text-dark rounded shadow-sm"></div>'
  ).prependTo(document.body);
  // if (window.logo) $('<div id="logo" class="mb-4"><img src="' + logo + '"></div>').appendTo(cont);
  // $('<h1 class="h3 mb-3">' + document.title + '</h1>').appendTo(cont);
  var userCont = $(
    '<div class="mb-3"><label for="user" class="form-label">Benutzername</label></div>'
  ).appendTo(cont);
  $(
    '<input id="user" type="text" class="form-control form-control-lg" autofocus>'
  )
    .appendTo(userCont)
    .keydown(function (evt) {
      if (evt.keyCode == 13) submit();
    });
  var passwordCont = $(
    '<div class="mb-3"><label for="password" class="form-label">Passwort</label></div>'
  ).appendTo(cont);
  $(
    '<input id="password" type="password" class="form-control form-control-lg">'
  )
    .appendTo(passwordCont)
    .keydown(function (evt) {
      if (evt.keyCode == 13) submit();
    });
  var submitCont = $('<div class=""></div>').appendTo(cont);
  $(
    '<button id="submit" type="submit" class="btn btn-primary">Anmelden</button>'
  )
    .appendTo(submitCont)
    .on('click', submit);
  $('<div id="info" class="alert alert-warning mt-3"></div>').appendTo(cont);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function submit() {
  var pm = {func: 'login'};
  pm.user = $('#user').val();
  pm.password = $('#password').val();
  pm.password = md5(pm.password);
  if (localStorage.getItem('debug')) pm.debug = localStorage.getItem('debug');
  $.post(endpoint, pm, function (xhr) {
    if (xhr.warning) $('#info').html(xhr.warning);
    if (xhr.next) {
      load(xhr.next, xhr);
      Cookies.set('login', pm.user + ',' + pm.password, {expires: 7});
    }
  });
}
